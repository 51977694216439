import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  apiV2EnergyAnalysisUnitConfGroupSavePost,
  apiV2EnergyUnitBoardRecomputePost,
} from '@maxtropy/device-customer-apis-v2';
import { Steps, Form, DatePicker, Modal, Button, Radio, TreeSelect } from '@maxtropy/components';
import { Space } from 'antd';
import styles from './index.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import check from '../assets/check.png';
export enum StepsType {
  First = 0,
  Second,
  Third,
}
type EffectiveDateModalProps = {
  // 分析组id
  unitId: string;
  open: boolean;
  closeModal: () => void;
  changedUnitConfGroupListDate?: string;
  refresh: () => void;
  setUnitConfGroupDetailActiveTab: React.Dispatch<React.SetStateAction<string>>;
  treeData?: any;
};

type FormProps = {
  effectiveDate: Dayjs;
};

// 生效日期选择
export default function EffectiveDateModal(props: EffectiveDateModalProps) {
  const { open, closeModal, unitId, changedUnitConfGroupListDate, refresh, setUnitConfGroupDetailActiveTab, treeData } =
    props;
  const [form] = Form.useForm<FormProps>();
  const [current, setCurrent] = useState<number>(0);
  const [effectiveDate, setEffectiveDate] = useState<Dayjs>(dayjs());
  const [energyUnitIds, setEnergyUnitIds] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const energyUnitId = Form.useWatch('energyUnitIds', form);

  useEffect(() => {
    // 全选按钮返回值并没有传labelInValue 的值，所以需要判断
    if (current === 1) {
      if (energyUnitId && energyUnitId?.[0]?.value) {
        const mapData = energyUnitId.map((i: { value: string }) => Number(i.value));
        setEnergyUnitIds(mapData);
      } else {
        setEnergyUnitIds(energyUnitId.map((i: string) => Number(i)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitId]);
  const onFinish = () => {
    if (isNil(effectiveDate)) return;
    setLoading(true);
    const promises = effectiveDate.isAfter(dayjs(), 'day')
      ? [
          apiV2EnergyAnalysisUnitConfGroupSavePost({
            unitId,
            effectiveDate: effectiveDate.format('YYYY-MM-DD'),
          }).onError(error => {
            setLoading(false);
            throw error;
          }),
        ]
      : [
          apiV2EnergyUnitBoardRecomputePost({
            energyUnitIds: energyUnitIds,
            startDate: effectiveDate.format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
          }).onError(error => {
            setLoading(false);
            throw error;
          }),
          apiV2EnergyAnalysisUnitConfGroupSavePost({
            unitId,
            effectiveDate: effectiveDate.format('YYYY-MM-DD'),
          }).onError(error => {
            setLoading(false);
            throw error;
          }),
        ];

    Promise.all(promises)
      .then(res => {
        Modal.success({
          content: '保存成功',
          onOk: () => {
            setUnitConfGroupDetailActiveTab('1');
            refresh();
          },
        });
        setEffectiveDate(dayjs());
        setEnergyUnitIds([]);
      })
      .catch(err => {
        Modal.error({
          content: '保存失败',
        });
      })
      .finally(() => {
        setLoading(false);
        form.setFieldsValue({ effectiveDate: dayjs() });
        setEffectiveDate(dayjs());
        closeModal();
        setCurrent(0);
      });
  };

  const next = () => {
    if (current === 1) {
      if (effectiveDate.isAfter(dayjs(), 'day')) {
        setCurrent(current + 1);
      } else {
        if (isNil(energyUnitIds) || energyUnitIds.length === 0) {
          Modal.warning({ content: '请选择用能单元' });
          return;
        } else {
          setCurrent(current + 1);
        }
      }
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    form.setFieldsValue({ effectiveDate });
    setCurrent(current - 1);
  };

  return (
    <>
      <Modal
        title="选择生效时间"
        okText="确认保存"
        open={open}
        onCancel={() => {
          closeModal();
          form.setFieldsValue({ effectiveDate: dayjs() });
          setCurrent(0);
          setEffectiveDate(dayjs());
        }}
        size="large"
        destroyOnClose
        footer={
          <>
            {current === StepsType.First && (
              <Space size={8}>
                <Button
                  onClick={() => {
                    setEffectiveDate(dayjs());
                    form.setFieldsValue({ effectiveDate: dayjs() });
                    closeModal();
                    setCurrent(0);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={next}>
                  下一步
                </Button>
              </Space>
            )}
            {effectiveDate.isAfter(dayjs(), 'day') ? (
              <>
                {current === StepsType.Second && (
                  <Space>
                    <Button type="primary" onClick={onFinish}>
                      确认保存
                    </Button>
                  </Space>
                )}
              </>
            ) : (
              <>
                {current === StepsType.Second && (
                  <Space size={8}>
                    <Button onClick={prev}>上一步</Button>
                    <Button type="primary" onClick={next}>
                      下一步
                    </Button>
                  </Space>
                )}
                {current === StepsType.Third && (
                  <Space>
                    <Button loading={loading} type="primary" onClick={onFinish}>
                      确认保存
                    </Button>
                  </Space>
                )}
              </>
            )}
          </>
        }
      >
        <Form
          preserve={false}
          form={form}
          labelAlign="left"
          onFinish={onFinish}
          initialValues={{ effectiveDate: dayjs(), radio: 1 }}
        >
          <div className={styles.steps}>
            {effectiveDate.isAfter(dayjs(), 'day') ? (
              <Steps
                current={current}
                items={[
                  {
                    title: '选择时间',
                  },
                  {
                    title: '完成',
                  },
                ]}
              />
            ) : (
              <Steps
                current={current}
                items={[
                  {
                    title: '选择时间',
                  },
                  {
                    title: '单元数据计算',
                  },
                  {
                    title: '完成',
                  },
                ]}
              />
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.firstStep}>
              {current === StepsType.First && (
                <Form.Item
                  rules={[{ required: true }]}
                  name="effectiveDate"
                  label="生效日期"
                  extra={changedUnitConfGroupListDate ? `保存后会覆盖更改用能单元未来配置` : ''}
                >
                  <DatePicker
                    style={{ width: '75%' }}
                    onChange={(value, values) => {
                      setEffectiveDate(value);
                    }}
                  />
                </Form.Item>
              )}
              {effectiveDate.isAfter(dayjs(), 'day') ? (
                <>
                  {current === StepsType.Second && (
                    <div className={styles.finish}>
                      <img src={check} alt="" style={{ width: 56, height: 56 }} />
                      应用完成
                    </div>
                  )}
                </>
              ) : (
                <>
                  {current === StepsType.Second && (
                    <>
                      <Form.Item rules={[{ required: true }]} name="radio" label="单元数据重计算">
                        <Radio.Group>
                          <Radio value={1} defaultChecked>
                            单元数据重计算
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        rules={[{ required: true, message: '请选择用能单元' }]}
                        name="energyUnitIds"
                        label="用能单元"
                      >
                        <TreeSelect
                          popupClassName={styles.treeSelect}
                          treeData={treeData}
                          checkAllAble={true}
                          maxTagCount={'responsive'}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          // fieldNames={{ label: 'name', value: 'id', children: 'childList' }}
                          treeCheckable
                          showCheckedStrategy={TreeSelect.SHOW_ALL}
                          treeCheckStrictly={true}
                          placeholder="请选择"
                        />
                      </Form.Item>
                      <ExclamationCircleOutlined style={{ color: 'var(--mx-warning-color)', fontSize: 12 }} />
                      <span className={styles.txt}>
                        数据重计算需要5—30分钟，若相关页面数据未更新可联系系统管理员排查。
                      </span>
                    </>
                  )}
                  {current === StepsType.Third && (
                    <div className={styles.finish}>
                      <img src={check} alt="" style={{ width: 56, height: 56 }} />
                      应用完成
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
