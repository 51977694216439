import { Select } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
export function splitTime(minutesGap: number) {
  let gap = 1;
  let hours = 24;
  let startTime = 0;
  if (minutesGap > 60 || minutesGap <= 0) {
    gap = 1;
  }
  gap = Math.floor(60 / minutesGap);

  let amount = hours * gap; // 分割数量

  let temp: string[] = [];
  for (let i = 0; i <= amount; i++) {
    let hours = parseInt(String(startTime / 60));
    let minus = startTime % 60;
    let formateH = hours < 10 ? '0' + hours : hours;
    let formateM = minus < 10 ? '0' + minus : minus;
    temp.push(formateH + ':' + formateM);
    startTime = startTime + minutesGap;
  }
  return temp;
}
interface Iprops {
  onChange?: (v?: string[]) => void;
  value?: string[];
  disabled?: boolean;

  disabledRangeTime?: string[];
}
const timeSelectList = splitTime(30);
const TimeRangePicker: React.FC<Iprops> = ({ value, onChange, disabled, disabledRangeTime = [] }) => {
  // 后续考虑使用useMergedState
  const [preValue, setPreValue] = useState<string>();
  const [nextValue, setNextValue] = useState<string>();
  const preValueRef = useRef<string>();
  const nextValueRef = useRef<string>();

  const preOptions = useMemo(() => {
    let startTime = disabledRangeTime[0];
    let endTime = disabledRangeTime[1];
    return timeSelectList
      .map(item => ({
        value: item,
        label: item,
        disabled:
          !!(nextValue && nextValue <= item) || !!(startTime && item >= startTime && endTime && item <= endTime),
      }))
      .filter(item => !item.disabled);
  }, [nextValue, disabledRangeTime]);
  const nextOptions = useMemo(() => {
    let startTime = disabledRangeTime[0];
    let endTime = disabledRangeTime[1];
    return timeSelectList
      .map(item => ({
        value: item,
        label: item,
        disabled: !!(preValue && preValue >= item) || !!(startTime && item >= startTime && endTime && item <= endTime),
      }))
      .filter(item => !item.disabled);
  }, [preValue, disabledRangeTime]);
  const preChange = (v: string) => {
    setPreValue(v);
    preValueRef.current = v;
    if (nextValueRef.current) {
      onChange?.([v, nextValueRef.current]);
    }
  };
  const nextChange = (v: string) => {
    setNextValue(v);
    nextValueRef.current = v;
    if (preValueRef.current) {
      onChange?.([preValueRef.current, v]);
    }
  };
  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0]) {
        setPreValue(value[0]);
        preValueRef.current = value[0];
      }
      if (value[1]) {
        setNextValue(value[1]);
        nextValueRef.current = value[1];
      }
    }
  }, [value]);
  return (
    <>
      <div className={styles.flex_outer}>
        <Select
          style={{ width: 120 }}
          disabled={disabled}
          value={preValue}
          onChange={preChange}
          options={preOptions}
        ></Select>
        <span className={styles.split_line}>-</span>
        <Select
          style={{ width: 120 }}
          disabled={disabled}
          value={nextValue}
          onChange={nextChange}
          options={nextOptions}
        ></Select>
      </div>
    </>
  );
};
export default TimeRangePicker;
