import { ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from '@maxtropy/components';
import { Col, Row, Space, Button as AntDButton, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import TimeRangePicker from './TimeRangePicker';
import { WorkShiftsItem } from '.';
import { apiV2WorkShiftCreatePost, apiV2WorkShiftUpdatePost } from '@maxtropy/device-customer-apis-v2';
interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  op?: string;
  row?: WorkShiftsItem;
}

const ShiftOpsModal: React.FC<Iprops> = ({ op = 'add', cancel, confirm, row }) => {
  const opTitle = useMemo(() => {
    return op === 'add' ? '新增班次' : '编辑班次';
  }, [op]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (op === 'edit' && row) {
      let timeSegArr = (row.timeSeg as string).split(';');
      let timeData = timeSegArr.map(item => ({
        timeSeg: item.split('-'),
      }));
      form.setFieldsValue({
        name: row.name,
        timeSegs: timeData,
      });
    }
  }, [op, row, form]);

  const confirmBtn = () => {
    const opApi = op === 'add' ? apiV2WorkShiftCreatePost : apiV2WorkShiftUpdatePost;

    form.validateFields().then(res => {
      setLoading(true);
      const body = {
        name: res.name?.trim(),
        timeSeg: res.timeSegs.map((item: { timeSeg: any[] }) => item.timeSeg.join('-')),
      };
      opApi(op === 'add' ? body : { ...body, workShiftId: row?.id })
        .then(_ => confirm?.())
        .finally(() => setLoading(false));
    });
  };
  // 班次时间是否存在班次日历中, 若存在,禁止编辑
  const editDisabled = useMemo(() => row?.inUse && op === 'edit', [row, op]);
  return (
    <>
      <Modal
        open
        title={opTitle}
        size="normal"
        onCancel={() => cancel?.()}
        footer={
          <Space size={10}>
            <Button onClick={() => cancel?.()}>取消</Button>
            <Button loading={loading} type="primary" onClick={confirmBtn}>
              确定
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          labelCol={{ style: { width: 80 } }}
          initialValues={{
            timeSegs: [{ timeSeg: [] }],
          }}
        >
          <Row>
            <Col span={22}>
              <Form.Item
                label="班次名称"
                name="name"
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!value || !value.trim()) {
                        throw new Error('班次名称不能为空');
                      }
                      //不允许\、/、:、*、?、"、<、>、|字符。
                      let regEn = /[\\<>*?:|"/]+/im;
                      if (regEn.test(value)) {
                        throw new Error('班次名称不能包含\\、/、:、*、?、"、<、>、|字符');
                      }
                    },
                  },
                ]}
              >
                <Input maxLength={15} placeholder="请输入"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="timeSegs">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  // 互相添加依赖 [['timeSegs', 0, 'timeSeg']]
                  const dependencies = fields
                    .filter(item => item.name !== name)
                    .map(a => ['timeSegs', a.name, 'timeSeg']);
                  return (
                    <Row key={key}>
                      <Col span={22}>
                        <Form.Item
                          dependencies={dependencies}
                          {...restField}
                          label={name === 0 ? '班次时间' : <span style={{ display: 'none' }}>none</span>}
                          colon={name === 0}
                          name={[name, 'timeSeg']}
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                            ({ getFieldValue }) => ({
                              validator: async (_, value) => {
                                if (!value || value.length === 0) {
                                  throw new Error('请选择班次时间范围');
                                }
                                // 校验时间段是否有重叠, 只在最后一行显示error信息
                                if (name > 0 && name === fields.length - 1) {
                                  let hasSame = false;
                                  let collects = getFieldValue('timeSegs').filter((f: any) => f);
                                  let mapsNames = collects.map((item: any) => item.timeSeg);
                                  let strTimes = mapsNames.map((item: string[]) => item.join('-'));
                                  let lessTimes = [...new Set(strTimes)];
                                  if (lessTimes.length < strTimes.length) {
                                    // 去重后, 说明有重复项 eg: 00:00-01:00 === 00:00-01:00
                                    hasSame = true;
                                  } else {
                                    // 判断某一刻是否在区间内 eg: 00:30 in [00:00, 01:00]
                                    let sigleTimeArr = mapsNames.flat();
                                    hasSame = sigleTimeArr.some((time: string) => {
                                      return collects.some((item: any) => {
                                        let timeRange = item.timeSeg;
                                        let start = timeRange[0];
                                        let end = timeRange[1];
                                        return time > start && time < end;
                                      });
                                    });
                                  }
                                  if (hasSame) {
                                    throw new Error('班次时间范围有重叠, 请重新选择');
                                  }
                                }
                              },
                            }),
                          ]}
                        >
                          <TimeRangePicker disabled={editDisabled} />
                        </Form.Item>
                      </Col>
                      {!editDisabled && (
                        <Col span={2}>
                          {name > 0 && <MinusCircleOutlined style={{ padding: 9 }} onClick={() => remove(name)} />}
                        </Col>
                      )}
                    </Row>
                  );
                })}
                <Row>
                  <Col span={22}>
                    <div style={{ marginLeft: 80 }}>
                      <AntDButton
                        disabled={fields.length >= 3 || editDisabled}
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        <span className={styles.timeText}>+ 新增班次时间</span>
                      </AntDButton>
                      {op === 'edit' && (
                        <div className={styles.tips}>
                          <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                          &nbsp;班次在班次日历后不可编辑班次时间
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ShiftOpsModal;
