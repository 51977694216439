import { Space } from 'antd';
import classnames from 'classnames/bind';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ShiftTag from '../../ShiftTag';
import TimeGrid from '../../TimeGrid';
import styles from '../index.module.scss';
import { isNil } from 'lodash-es';
import { apiV2WorkShiftListPost, V2WorkShiftCalendarDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
import { Modal } from '@maxtropy/components';

export const colorsItems: ColorItem[] = [
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
];

type ColorItem = {
  bg: string;
  border: string;
};
export type WorkShiftsItem = Exclude<V2WorkShiftCalendarDetailPostResponse['workShifts'], undefined>[number];
export type ShiftInfo = WorkShiftsItem & {
  colors?: ColorItem;
};
const cx = classnames.bind(styles);

export const createRangeTimeStr = (pars: string) => {
  let time = pars.split(';');
  let str = '';
  if (time.length <= 1) {
    str = `(${time})`;
    return str;
  }
  time.forEach((item, index) => {
    str += index > 0 ? `~(${item})` : `(${item})`;
  });
  return `(${time.join(' ; ')})`;
};
type NumericArray = number[];

type TimeSegment = {
  id: number;
  name: string;
  timeSeg: string;
  inUse?: boolean;
  deleted: boolean;
  colors: {
    bg: string;
    border: string;
  };
};

type extraWorkShiftListType = TimeSegment[][];
interface Iprops {
  FieldIndex: number;
  modalApi?: any;
  value?: any;
  onChange?: (val: NumericArray) => void;
  onDataFromChild: (val: any, FieldIndex: any) => void;
  extraWorkShiftList: extraWorkShiftListType;
  setExtraWorkShiftList: (val: any) => void;
}
const DragEditCalendar: React.FC<Iprops> = ({
  FieldIndex,
  modalApi,
  value,
  onChange,
  onDataFromChild,
  extraWorkShiftList,
  setExtraWorkShiftList,
}) => {
  const [extraUnshiftList, setExtraUnShiftList] = useState<ShiftInfo[]>([]);
  const colors = useRef<ColorItem[]>([...colorsItems]);

  const [unshiftList, setUnShiftList] = useState<ShiftInfo[]>([]);
  const [selectShiftList, setSelectShiftList] = useState<ShiftInfo[]>([]);

  useEffect(() => {
    console.log(extraWorkShiftList, 'extraWorkShiftList');
    // 新增
    apiV2WorkShiftListPost({}).then(list => {
      let unSelects = (list.list ?? []).filter(item => !value?.includes(item.id));
      setExtraUnShiftList([...unSelects]);
      setUnShiftList(list.list ?? []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let workShiftIds: NumericArray;
    if (!isNil(extraWorkShiftList[FieldIndex])) {
      workShiftIds = extraWorkShiftList[FieldIndex].map((item: TimeSegment) => item.id);
      onDataFromChild(workShiftIds, FieldIndex);
    } else {
      workShiftIds = selectShiftList.map((item: any) => item.id);
      onDataFromChild(workShiftIds, FieldIndex);
    }
    if (!isNil(workShiftIds) && !isNil(onChange)) {
      onChange(workShiftIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraWorkShiftList, selectShiftList]);

  const hasExsitTime = (item: ShiftInfo, shiftTimes: string[]) => {
    let timeArr = (item.timeSeg as string).split(';');
    let exist = timeArr.some(time => {
      if (shiftTimes.includes(time)) return true;
      let startTime = time.split('-')[0];
      let endTime = time.split('-')[1];
      return shiftTimes.some(exist => {
        let tempArr = exist.split('-');
        return (
          (startTime > tempArr[0] && startTime < tempArr[1]) ||
          (endTime > tempArr[0] && endTime < tempArr[1]) ||
          (tempArr[0] < endTime && tempArr[0] > startTime) ||
          (tempArr[1] < endTime && tempArr[1] > startTime)
        );
      });
    });
    return exist;
  };

  const extraDragEnd = (result: any) => {
    // 最多5个班次
    if (extraWorkShiftList[FieldIndex].length >= 5) return;
    let { destination, source } = result;
    if (!destination || !source) return;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'droppableRight') {
        // 拖拽到了右边区域 左边list删除, 右边list增加
        onResultChange(extraUnshiftList, extraWorkShiftList[FieldIndex], result);
      }
    }
  };

  const shiftTimes = useMemo(() => {
    console.log(extraWorkShiftList[FieldIndex]?.map((item: any) => item.timeSeg.split(';')).flat());
    return extraWorkShiftList[FieldIndex]?.map((item: any) => item.timeSeg.split(';')).flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraWorkShiftList]);

  const onResultChange = (removeList: ShiftInfo[], newList: ShiftInfo[], result: any) => {
    let flag = removeList.some(item => {
      if (item.id === +result.draggableId) {
        // 判断时间有没有重叠
        let exist = hasExsitTime(item, shiftTimes);
        if (exist) {
          Modal.warning({
            title: '班次时间段重复, 请重新选择',
          });
          return false;
        }
        // 分配颜色
        let tempColors = colors.current[0];
        colors.current.shift();
        item.colors = tempColors;
        newList.splice(result.destination.index, 0, item);
        // 排序
        newList.sort((a, b) => {
          let aValue = (a.timeSeg as string).split('-')[0];
          let bValue = (b.timeSeg as string).split('-')[0];
          return aValue > bValue ? 1 : -1;
        });
        return true;
      }
      return false;
    });
    if (!flag) return;
    removeList.splice(result.source.index, 1);
    setExtraUnShiftList([...removeList]);

    setExtraWorkShiftList(extraWorkShiftList.map((arr: any, i: number) => (i === FieldIndex ? [...newList] : arr)));
  };

  const onExtraTagClose = (item: any, dragindex: number, index: number) => {
    colors.current.unshift(item.colors);
    extraUnshiftList.push(item);
    extraUnshiftList.sort((a: any, b: any) => a.id - b.id);
    setExtraUnShiftList([...extraUnshiftList]);
    const updatedArray = [...extraWorkShiftList[FieldIndex]];
    updatedArray.splice(dragindex, 1); // 删除指定位置的数组元素
    setExtraWorkShiftList(extraWorkShiftList.map((arr: any, i: number) => (i === FieldIndex ? updatedArray : arr)));
  };

  const shiftTimesEdit = useMemo(() => {
    return selectShiftList.map(item => (item.timeSeg as string).split(';')).flat();
  }, [selectShiftList]);

  // removeList需要删除，newList为添加，result拖拽的元素信息
  const onResultChangeEdit = (removeList: ShiftInfo[], newList: ShiftInfo[], result: any) => {
    let flag = removeList.some(item => {
      if (item.id === +result.draggableId) {
        // 判断时间有没有重叠
        let exist = hasExsitTime(item, shiftTimesEdit);
        if (exist) {
          Modal.warning({
            title: '班次时间段重复, 请重新选择',
          });
          return false;
        }
        // 分配颜色
        let tempColors = colors.current[0];
        colors.current.shift();
        item.colors = tempColors;
        newList.splice(result.destination.index, 0, item);
        // 排序
        newList.sort((a, b) => {
          let aValue = (a.timeSeg as string).split('-')[0];
          let bValue = (b.timeSeg as string).split('-')[0];
          return aValue > bValue ? 1 : -1;
        });
        return true;
      }
      return false;
    });
    if (!flag) return;
    removeList.splice(result.source.index, 1);
    setUnShiftList([...removeList]);
    setSelectShiftList([...newList]);
  };
  const dragEndEdit = (result: any) => {
    // 最多5个班次
    if (selectShiftList.length >= 5) return;
    let { destination, source } = result;
    if (!destination || !source) return;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'droppableRight') {
        // 拖拽到了右边区域 左边list删除, 右边list增加
        onResultChangeEdit(unshiftList, selectShiftList, result);
      }
    }
  };
  // onTagClose 函数：这个函数用于移除右侧已选择的班次标签，同时处理颜色和班次列表的变化。
  const onTagCloseEdit = (item: any, index: number) => {
    colors.current.unshift(item.colors);
    unshiftList.push(item);
    unshiftList.sort((a, b) => (a.id as number) - (b.id as number));
    setUnShiftList([...unshiftList]);
    selectShiftList.splice(index, 1);
    setSelectShiftList([...selectShiftList]);
  };

  return (
    <>
      {!isNil(extraWorkShiftList[FieldIndex]) ? (
        <DragDropContext onDragEnd={extraDragEnd}>
          <div className={styles.drag_box}>
            <Droppable droppableId="droppableLeft">
              {droppableProvidedLeft => (
                <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                  {extraUnshiftList.map((item: any, dragindex: number) => (
                    <Draggable
                      isDragDisabled={extraWorkShiftList[FieldIndex].length >= 5}
                      key={'drag' + item.id}
                      draggableId={`${item.id}`}
                      index={dragindex}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                          >
                            {item.name}
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvidedLeft.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="droppableRight" direction="horizontal">
              {droppableProvidedRight => (
                <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                  <div className={styles.drag_right_top}>
                    <div>
                      <Space size={[5, 8]} wrap>
                        {extraWorkShiftList[FieldIndex].map((item: any, dragindex: number) => (
                          <Draggable isDragDisabled key={'drop' + item.id} draggableId={`${item.id}`} index={dragindex}>
                            {(provided, snapshot) => {
                              return (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <ShiftTag
                                    bg={item?.colors?.bg}
                                    borderBg={item?.colors?.border}
                                    onClose={() => onExtraTagClose(item, dragindex, FieldIndex)}
                                  >
                                    {item.name + createRangeTimeStr(item.timeSeg)}
                                  </ShiftTag>
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                      </Space>
                      {droppableProvidedRight.placeholder}
                    </div>
                  </div>
                  <div className={styles.drag_right_bottom}>
                    <TimeGrid timeArr={extraWorkShiftList[FieldIndex]} />
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      ) : (
        <DragDropContext onDragEnd={dragEndEdit}>
          <div className={styles.drag_box}>
            <Droppable droppableId="droppableLeft">
              {droppableProvidedLeft => (
                <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                  {unshiftList.map((item: any, dragindex: number) => (
                    <Draggable
                      isDragDisabled={selectShiftList.length >= 5}
                      key={'drag' + item.id}
                      draggableId={`${item.id}`}
                      index={dragindex}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                          >
                            {item.name}
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvidedLeft.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="droppableRight" direction="horizontal">
              {droppableProvidedRight => (
                <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                  <div className={styles.drag_right_top}>
                    <div>
                      <Space size={[5, 8]} wrap>
                        {selectShiftList.map((item: any, dragindex: number) => (
                          <Draggable isDragDisabled key={'drop' + item.id} draggableId={`${item.id}`} index={dragindex}>
                            {(provided, snapshot) => {
                              return (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <ShiftTag
                                    bg={item?.colors?.bg}
                                    borderBg={item?.colors?.border}
                                    onClose={() => onTagCloseEdit(item, dragindex)}
                                  >
                                    {item.name + createRangeTimeStr(item.timeSeg)}
                                  </ShiftTag>
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                      </Space>
                      {droppableProvidedRight.placeholder}
                    </div>
                  </div>
                  <div className={styles.drag_right_bottom}>
                    <TimeGrid timeArr={selectShiftList} />
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
    </>
  );
};
export default DragEditCalendar;
