import { splitTime } from '@/pages/ShiftManage/TimeRangePicker';
import { ShiftInfo } from '../ShiftOpModal/ShiftOps';
import styles from './index.module.scss';

const timesAmount = splitTime(30);
timesAmount.shift(); // 去掉00:00
const TimeGrid: React.FC<{ timeArr: ShiftInfo[] }> = ({ timeArr }) => {
  return (
    <>
      <div className={styles.time_box}>
        {timesAmount.map(item => {
          let has = timeArr?.find(info => {
            let timeArr = (info.timeSeg as string).split(';');
            return timeArr.some(d => {
              let rangeTime = d?.split('-');
              if (rangeTime) {
                if (item > rangeTime[0] && item <= rangeTime[1]) {
                  return true;
                }
              }
              return false;
            });
          });
          if (has) {
            return <div key={item} className={styles.time_gap} style={{ backgroundColor: has.colors?.border }}></div>;
          } else {
            return <div key={item} className={styles.time_gap}></div>;
          }
        })}
      </div>
      <div className={styles.time_fix}>
        <span>00:00</span>
        <span>12:00</span>
        <span>24:00</span>
      </div>
    </>
  );
};
export default TimeGrid;
