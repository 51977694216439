import { CheckCircleFilled } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  FormTitle,
  Steps,
  SubContent,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Form, Col, Row, Result } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ApplicationUnitTable from './components/ApplicationUnitTable';
import styles from './index.module.scss';
import {
  apiV2EnergyAnalysisListByOu2Post,
  apiV2WorkShiftCalendarConfigAddPost,
  apiV2WorkShiftCalendarConfigInfoPost,
  apiV2WorkShiftCalendarConfigUpdatePost,
  V2WorkShiftCalendarConfigInfoPostResponse,
  V2WorkShiftCalendarConfigUpdatePostRequest,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  isEdit?: boolean;
}

const formatString = 'YYYY-MM';

const steps = [
  {
    title: '选择时间',
  },
  {
    title: '选择单元',
  },
  {
    title: '完成',
  },
];

const items = steps.map(item => ({ key: item.title, title: item.title }));

export type GroupDetails = Exclude<V2WorkShiftCalendarConfigInfoPostResponse['groupDetails'], undefined>[number];
export type AddLink = Exclude<V2WorkShiftCalendarConfigUpdatePostRequest['addLink'], undefined>[number];

const AddToUnit: React.FC<Props> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [current, setCurrent] = useState<number>(0);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const workShiftCalendarId = urlSearchParams.get('workShiftCalendarId') || undefined;
  const [newGroupDetails, setNewGroupDetails] = useState<GroupDetails[]>([]); // 新用能分析组
  const [oldGroupDetails, setOldGroupDetails] = useState<GroupDetails[]>([]); // 旧用能分析组
  const [toBacKGroupDetails, setToBacKGroupDetails] = useState<GroupDetails[]>([]); // 新增时返回上一步后用于清空设置
  const [update, updateFn] = useUpdate();
  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '修改应用配置' : '应用到单元'}` }];
  }, [isEdit]);

  useEffect(() => {
    if (id && isEdit) {
      setLoading(true);
      apiV2WorkShiftCalendarConfigInfoPost({ id })
        .then(res => {
          form.setFieldsValue({
            startDate: dayjs(res.startDate),
            endDate: dayjs(res.endDate),
            groupDetails: res.groupDetails,
          });
          setOldGroupDetails(res.groupDetails ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);

  useEffect(() => {
    if (!isEdit) {
      // 获取用能分析组
      apiV2EnergyAnalysisListByOu2Post({}).then(res => {
        form.setFieldsValue({
          groupDetails: res.list ?? [],
        });
        setToBacKGroupDetails(res.list ?? []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const next = () => {
    if (current === 0) {
      setStartDate(dayjs(form.getFieldValue('startDate')).startOf('M').format('YYYY-MM-DD'));
      setEndDate(dayjs(form.getFieldValue('endDate')).startOf('M').format('YYYY-MM-DD'));
      updateFn();
    }
    form.submit();
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = (v: V2WorkShiftCalendarConfigUpdatePostRequest) => {
    if (current === 2) {
      if (isEdit && id) {
        const groupDetails = newGroupDetails
          .map(newGroupDetail => {
            return oldGroupDetails
              .map(oldGroupDetail => {
                if (newGroupDetail.energyGroupId === oldGroupDetail.energyGroupId) {
                  const newUnitIds = newGroupDetail?.energyUnits?.map(i => i?.energyUnitId);
                  const oldUnitIds = oldGroupDetail?.energyUnits?.map(i => i?.energyUnitId);
                  const addUnitIds = newUnitIds?.filter(i => !oldUnitIds?.includes(i));
                  const delUnitIds = oldUnitIds?.filter(i => !newUnitIds?.includes(i));
                  if ((addUnitIds && addUnitIds.length > 0) || (delUnitIds && delUnitIds.length))
                    return {
                      energyGroupId: newGroupDetail.energyGroupId,
                      addUnitIds,
                      delUnitIds,
                    };
                }
                return undefined;
              })
              .filter(i => i !== undefined)
              .at(0);
          })
          .filter(i => i !== undefined);

        const updateBody = {
          addLink: groupDetails
            .filter(i => i?.addUnitIds && i.addUnitIds.length > 0)
            .map(i => {
              return {
                energyGroupId: i?.energyGroupId,
                energyUnitIds: i?.addUnitIds,
              };
            }) as AddLink[],

          delLink: groupDetails
            .filter(i => i?.delUnitIds && i.delUnitIds.length > 0)
            .map(i => {
              return {
                energyGroupId: i?.energyGroupId,
                energyUnitIds: i?.delUnitIds,
              };
            })
            .filter(i => i.energyUnitIds !== null) as AddLink[],
        };
        apiV2WorkShiftCalendarConfigUpdatePost({ workShiftCalendarConfigId: id, ...updateBody });
        return;
      }
      const addBody = {
        workShiftCalendarId,
        startDate,
        endDate,
        link: newGroupDetails?.map(i => {
          return {
            energyGroupId: i.energyGroupId,
            energyUnitIds: i.energyUnits?.map(unit => unit.energyUnitId) ?? [],
          };
        }) as AddLink[],
      };
      apiV2WorkShiftCalendarConfigAddPost(addBody);
    }
  };

  const appUnitOnChange = (v: GroupDetails[]) => {
    setNewGroupDetails(v);
  };

  const onDateChange = () => {
    form.setFieldsValue({
      groupDetails: toBacKGroupDetails,
    });
  };

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`${isEdit ? '修改应用配置' : '应用到单元'}`} />
      <SubContent>
        <div className={styles.stepsStyle}>
          <Steps style={{ width: 1000 }} current={current} items={items} />
        </div>
        <Row>
          <Col span={20} offset={1}>
            <Form
              form={form}
              initialValues={{
                startDate: dayjs(dayjs().add(1, 'month'), formatString),
                endDate: dayjs(dayjs().add(13, 'month'), formatString),
              }}
              onFinish={(v: V2WorkShiftCalendarConfigUpdatePostRequest) => onFinish(v)}
            >
              {current === 0 && (
                <>
                  <Form.Item noStyle dependencies={['endDate']}>
                    {({ getFieldValue }) => {
                      const endDate = getFieldValue('endDate');
                      return (
                        <Form.Item
                          style={{ width: '100%', paddingTop: 60 }}
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 6 }}
                          label="起始时间"
                          name="startDate"
                        >
                          <DatePicker
                            disabled={isEdit}
                            allowClear={false}
                            picker="month"
                            style={{ width: '100%' }}
                            placeholder="请选择"
                            onChange={onDateChange}
                            disabledDate={c => {
                              return c.isAfter(dayjs(endDate)) || c.isBefore(dayjs(endDate).subtract(5, 'years'));
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>

                  <Form.Item noStyle dependencies={['startDate']}>
                    {({ getFieldValue }) => {
                      const startDate = getFieldValue('startDate');
                      return (
                        <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 6 }} label="结束时间" name="endDate">
                          <DatePicker
                            disabled={isEdit}
                            allowClear={false}
                            picker="month"
                            style={{ width: '100%' }}
                            placeholder="请选择"
                            onChange={onDateChange}
                            disabledDate={c => {
                              return c.isBefore(dayjs(startDate)) || c.isAfter(dayjs(startDate).add(5, 'years'));
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )}
              {current === 1 && (
                <Form.Item name="groupDetails" wrapperCol={{ span: 18, offset: 4 }}>
                  <ApplicationUnitTable
                    endDate={endDate}
                    startDate={startDate}
                    onChange={appUnitOnChange}
                    id={isEdit ? id : undefined}
                    isEdit={isEdit}
                  />
                </Form.Item>
              )}
              {current === 2 && (
                <Form.Item wrapperCol={{ span: 5, offset: 11 }}>
                  <Result
                    status="success"
                    title={
                      <span
                        style={{
                          fontSize: 12,
                          fontFamily: ' PingFangSC-Medium, PingFang SC',
                          fontWeight: 500,
                          color: 'rgba(255,255,255,0.8)',
                        }}
                      >
                        应用完成
                      </span>
                    }
                    extra={[
                      <div style={{ marginTop: 111 }}>
                        <Button>
                          <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>返回</Link>
                        </Button>
                      </div>,
                    ]}
                    icon={<CheckCircleFilled style={{ fontSize: 50 }} />}
                  />
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
        <div style={{ marginTop: 24, display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center' }}>
          {current > 0 && current !== steps.length - 1 && <Button onClick={() => prev()}>上一步</Button>}
          {(current === 0 || (current === 1 && form.getFieldValue('groupDetails').length > 0)) && (
            <Button type="primary" htmlType="submit" onClick={() => next()}>
              下一步
            </Button>
          )}
          {current !== steps.length - 1 && (
            <Button>
              <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>返回</Link>
            </Button>
          )}
        </div>
      </SubContent>
    </Wrapper>
  );
};
export default AddToUnit;
