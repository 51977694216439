import { RestType } from '@/api/shiftCalendar';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan } from '@maxtropy/components';
import { Checkbox, Col, Form, Input, message, Modal, Radio, Row, Space, Popconfirm, Table } from 'antd';
import classnames from 'classnames/bind';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ShiftTag from '../ShiftTag';
import TimeGrid from '../TimeGrid';
import styles from './index.module.scss';
import {
  apiV2WorkShiftCalendarDetailPost,
  apiV2WorkShiftListPost,
  V2WorkShiftCalendarDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
const cx = classnames.bind(styles);
type OptionSelect = { label: string; value: string };

// 0月末, 1-31代表1号-31号
const createMonthsSelect = () => {
  let amount = 31;
  let arr: OptionSelect[] = [];
  for (let i = 1; i <= amount; i++) {
    arr[i] = {
      label: `${i}号`,
      value: `${i}`,
    };
  }
  arr.push({
    label: '月末',
    value: '0',
  });
  return arr;
};
const createWeekSelect = () => {
  return [
    { label: '周日', value: '7' },
    { label: '周一', value: '1' },
    { label: '周二', value: '2' },
    { label: '周三', value: '3' },
    { label: '周四', value: '4' },
    { label: '周五', value: '5' },
    { label: '周六', value: '6' },
  ] as OptionSelect[];
};
export const colorsItems: ColorItem[] = [
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
];
export const formateRestType = {
  [RestType.WEEK]: '按周',
  [RestType.MONTH]: '按月',
};
const formateRestKey = {
  [RestType.WEEK]: 'weeks',
  [RestType.MONTH]: 'months',
};
type ColorItem = {
  bg: string;
  border: string;
};

export const options: Record<RestType, OptionSelect[]> = {
  [RestType.WEEK]: createWeekSelect(),
  [RestType.MONTH]: createMonthsSelect(),
};
interface Iprops {
  op?: string;
  id?: number;
  confirm?: () => void;
  cancel?: () => void;
}

export type WorkShiftsItem = Exclude<V2WorkShiftCalendarDetailPostResponse['workShifts'], undefined>[number];

export type ShiftInfo = WorkShiftsItem & {
  colors?: ColorItem;
};
// 是否存在重复数据
const hasExsitTime = (item: ShiftInfo, shiftTimes: string[]) => {
  let timeArr = (item.timeSeg ?? '').split(';');
  let exist = timeArr.some(time => {
    if (shiftTimes.includes(time)) return true;
    let startTime = time.split('-')[0];
    let endTime = time.split('-')[1];
    return shiftTimes.some(exist => {
      let tempArr = exist.split('-');
      return (
        (startTime > tempArr[0] && startTime < tempArr[1]) ||
        (endTime > tempArr[0] && endTime < tempArr[1]) ||
        (tempArr[0] < endTime && tempArr[0] > startTime) ||
        (tempArr[1] < endTime && tempArr[1] > startTime)
      );
    });
  });
  return exist;
};
// const hasExsitTime 函数：这个函数用于检查班次时间是否存在重叠。它接受一个 ShiftInfo 对象和一个字符串数组 shiftTimes，然后检查给定的班次时间是否与 shiftTimes 中的任何时间段重叠。如果有重叠，它返回 true，否则返回 false。
export const createRangeTimeStr = (pars: string) => {
  let time = pars.split(';');
  let str = '';
  if (time.length <= 1) {
    str = `(${time})`;
    return str;
  }
  time.forEach((item, index) => {
    str += index > 0 ? `~(${item})` : `(${item})`;
  });
  return `(${time.join(' ; ')})`;
};
// export const createRangeTimeStr 函数：这个函数接受一个字符串 pars，它将该字符串分割成多个时间段，并以特定格式返回。例如，如果输入是 "08:00-12:00;13:00-17:00"，它将返回 "(08:00-12:00 ; 13:00-17:00)"。
type ShiftCalendarItem = V2WorkShiftCalendarDetailPostResponse & {
  extra?: Record<string, string[]>;
  items?: any;
};

const ShiftOps: React.FC<Iprops> = ({ op = 'add', id, cancel, confirm }) => {
  const colors = useRef<ColorItem[]>([...colorsItems]);
  const opTitle = useMemo(() => {
    return op === 'add' ? '新增班次日历' : '编辑班次日历';
  }, [op]);
  const [form] = Form.useForm<ShiftCalendarItem>();
  const [unshiftList, setUnShiftList] = useState<ShiftInfo[]>([]);
  const [selectShiftList, setSelectShiftList] = useState<ShiftInfo[]>([]);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (id) {
      // 编辑
      Promise.all([apiV2WorkShiftListPost({}), apiV2WorkShiftCalendarDetailPost({ id })]).then(([list, detail]) => {
        let key = formateRestKey[detail.restType! as RestType];
        form.setFieldsValue({
          name: detail.name,
          restType: detail.restType,
          extra: {
            [key]: detail.restSeg?.split(',')!,
          },
          items: [
            {
              shiftType: 333,
              list: [
                {
                  key: '1',
                  startDate: '11',
                  endDate: '22',
                },
                {
                  key: '2',
                  startDate: '33',
                  endDate: '44',
                },
              ],
            },
          ],
        });
        let hasSelectIds: number[] = [];
        if (detail.workShifts) {
          hasSelectIds = detail.workShifts.map(item => item.id as number);

          setSelectShiftList(
            detail.workShifts?.map(item => {
              let tempColors = colors.current[0];
              colors.current.shift();
              return {
                ...item,
                colors: tempColors,
              };
            })
          );
        }
        // 过滤掉已选择的
        let unSelects = (list.list ?? []).filter(item => !hasSelectIds.includes(item.id as number));
        setUnShiftList([...unSelects]);
        console.log([...unSelects]);
      });
      return;
    }
    // 新增
    apiV2WorkShiftListPost({}).then(res => setUnShiftList(res.list ?? []));
  }, [id, form]);
  const confirmBtn = () => {
    form.validateFields().then(res => {
      let key = formateRestKey[res.restType! as RestType];
      let restSeg = res.extra?.[key]?.toString();
      let workShiftIds = selectShiftList.map(item => item.id);
      if (restSeg && workShiftIds.length === 0) {
        messageApi.error('请选择工作日班次!');
        return;
      }

      // if (op === 'edit' && id) {
      //   updateShiftCalendar(id, params).then(_ => {
      //     confirm?.();
      //   });
      //   return;
      // }
      // addShiftCalendar(params).then(_ => confirm?.());
    });
  };

  const shiftTimes = useMemo(() => {
    return selectShiftList.map(item => (item.timeSeg ?? '').split(';')).flat();
  }, [selectShiftList]);
  // removeList需要删除，newList为添加，result拖拽的元素信息
  const onResultChange = (removeList: ShiftInfo[], newList: ShiftInfo[], result: any) => {
    let flag = removeList.some(item => {
      if (item.id === +result.draggableId) {
        // 判断时间有没有重叠
        let exist = hasExsitTime(item, shiftTimes);
        if (exist) {
          modalApi.warning({
            title: '班次时间段重复, 请重新选择',
          });
          return false;
        }
        // 分配颜色
        let tempColors = colors.current[0];
        colors.current.shift();
        item.colors = tempColors;
        newList.splice(result.destination.index, 0, item);
        // 排序
        newList.sort((a, b) => {
          let aValue = (a.timeSeg ?? '').split('-')[0];
          let bValue = (b.timeSeg ?? '').split('-')[0];
          return aValue > bValue ? 1 : -1;
        });
        return true;
      }
      return false;
    });
    if (!flag) return;
    removeList.splice(result.source.index, 1);
    setUnShiftList([...removeList]);
    setSelectShiftList([...newList]);
  };
  const dragEnd = (result: any) => {
    // 最多5个班次
    if (selectShiftList.length >= 5) return;
    let { destination, source } = result;
    if (!destination || !source) return;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'droppableRight') {
        // 拖拽到了右边区域 左边list删除, 右边list增加
        onResultChange(unshiftList, selectShiftList, result);
      }
    }
  };
  // onTagClose 函数：这个函数用于移除右侧已选择的班次标签，同时处理颜色和班次列表的变化。
  const onTagClose = (item: any, index: number) => {
    colors.current.unshift(item.colors);
    unshiftList.push(item);
    unshiftList.sort((a, b) => (a.id as number) - (b.id as number));
    setUnShiftList([...unshiftList]);
    selectShiftList.splice(index, 1);
    setSelectShiftList([...selectShiftList]);
  };

  const getColumns = (remove: any) => {
    return [
      {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        render: (_: any, record: any, index: number) => {
          return <EllipsisSpan value={index + 1} />;
        },
      },
      {
        title: '起始日期',
        width: '35%',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item name={[index, 'startDate']}>
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: '结束日期',
        width: '40%',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item name={[index, 'endDate']}>
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: '操作',
        key: 'option',
        render: (_: any, record: any, index: number) => (
          <Popconfirm title="确认删除?" onConfirm={() => remove(index)}>
            <div>删除</div>
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <>
      <Modal
        title={opTitle}
        open
        width={800}
        onCancel={() => cancel?.()}
        footer={
          <Space size={10}>
            <Button onClick={() => cancel?.()}>取消</Button>
            <Button type="primary" onClick={confirmBtn}>
              确定
            </Button>
          </Space>
        }
      >
        <div className={styles.outer}>
          <div className={styles.form_content}>
            <Form
              form={form}
              initialValues={{
                restType: RestType.WEEK,
              }}
              labelAlign="left"
              labelCol={{ style: { width: 85 } }}
            >
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="name"
                    label="班次日历"
                    rules={[
                      { required: true, message: '' },
                      {
                        validator: async (_, value) => {
                          if (!value || !value.trim()) {
                            throw new Error('班次日历不能为空');
                          }
                          let regEn = /[\\<>*?:|"/]+/im;
                          if (regEn.test(value)) {
                            throw new Error('班次日历不能包含\\、/、:、*、?、"、<、>、|字符');
                          }
                        },
                      },
                    ]}
                  >
                    <Input placeholder="请输入" maxLength={15} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item name="restType" label="休息日设置">
                  <Radio.Group>
                    <Radio value={RestType.WEEK}>{formateRestType[RestType.WEEK]}</Radio>
                    <Radio value={RestType.MONTH}>{formateRestType[RestType.MONTH]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item noStyle dependencies={['restType']}>
                    {({ getFieldValue }) => {
                      let key = getFieldValue('restType') as RestType;
                      let ops = options[key];
                      let formName = formateRestKey[key];
                      return (
                        <>
                          <Form.Item
                            name={['extra', formName]}
                            colon={false}
                            label={<span style={{ opacity: 0 }}>none</span>}
                          >
                            <Checkbox.Group style={{ width: '100%' }}>
                              <Row>
                                {ops?.map(item => (
                                  <Col span={3} key={item.value}>
                                    <Checkbox value={item.value}>{item.label}</Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <div className={styles.drag_area}>
                <div className={styles.title}>
                  <span>工作日班次:</span>
                  <span className={styles.title_tips}>
                    <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                    &nbsp;可拖动左侧列表中需要的班次至右侧时间轴，完成班次配置
                  </span>
                </div>
                <DragDropContext onDragEnd={dragEnd}>
                  <div className={styles.drag_box}>
                    <Droppable droppableId="droppableLeft">
                      {droppableProvidedLeft => (
                        <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                          {unshiftList.map((item, index) => (
                            <Draggable
                              isDragDisabled={selectShiftList.length >= 5}
                              key={'drag' + item.id}
                              draggableId={`${item.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                                  >
                                    {item.name}
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                          {droppableProvidedLeft.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Droppable droppableId="droppableRight" direction="horizontal">
                      {droppableProvidedRight => (
                        <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                          <div className={styles.drag_right_top}>
                            <div>
                              <Space size={[5, 8]} wrap>
                                {selectShiftList.map((item, index) => (
                                  <Draggable
                                    isDragDisabled
                                    key={'drop' + item.id}
                                    draggableId={`${item.id}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <ShiftTag
                                            bg={item?.colors?.bg}
                                            borderBg={item?.colors?.border}
                                            onClose={() => onTagClose(item, index)}
                                          >
                                            {item.name + createRangeTimeStr(item.timeSeg as string)}
                                          </ShiftTag>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                              </Space>
                              {droppableProvidedRight.placeholder}
                            </div>
                          </div>
                          <div className={styles.drag_right_bottom}>
                            <TimeGrid timeArr={selectShiftList} />
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </div>

              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                    {fields.map((field, index) => {
                      return (
                        <Form.Item key={'items' + index}>
                          <Form.Item noStyle>
                            <Form.Item name={[field.name, 'shiftType']} className={styles.shiftType}>
                              <Input placeholder="输入班次类型" className={styles.shiftTypeInput} />
                            </Form.Item>

                            <Popconfirm title="确认删除?" onConfirm={() => remove(field.name)}>
                              <Button type="primary">删除</Button>
                            </Popconfirm>
                          </Form.Item>

                          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                            <Space key={field.key} direction="vertical">
                              <Form.List name={[field.name, 'list']}>
                                {(tableFields, { add, remove }) => {
                                  return (
                                    <>
                                      <Table dataSource={tableFields} columns={getColumns(remove)} pagination={false} />
                                      <Button onClick={() => add()} type="primary">
                                        新增
                                      </Button>
                                    </>
                                  );
                                }}
                              </Form.List>
                            </Space>
                          </div>

                          <div className={styles.title}>
                            <span>提示:</span>
                            <span className={styles.title_tips}>
                              <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                              &nbsp;可拖动左侧列表中需要的班次至右侧时间轴，完成班次配置
                            </span>
                          </div>
                          <DragDropContext onDragEnd={dragEnd}>
                            <div className={styles.drag_box}>
                              <Droppable droppableId="droppableLeft">
                                {droppableProvidedLeft => (
                                  <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                                    {unshiftList.map((item, index) => (
                                      <Draggable
                                        isDragDisabled={selectShiftList.length >= 5}
                                        key={'drag' + item.id}
                                        draggableId={`${item.id}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                                            >
                                              {item.name}
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                    {droppableProvidedLeft.placeholder}
                                  </div>
                                )}
                              </Droppable>
                              <Droppable droppableId="droppableRight" direction="horizontal">
                                {droppableProvidedRight => (
                                  <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                                    <div className={styles.drag_right_top}>
                                      <div>
                                        <Space size={[5, 8]} wrap>
                                          {selectShiftList.map((item, index) => (
                                            <Draggable
                                              isDragDisabled
                                              key={'drop' + item.id}
                                              draggableId={`${item.id}`}
                                              index={index}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <ShiftTag
                                                      bg={item?.colors?.bg}
                                                      borderBg={item?.colors?.border}
                                                      onClose={() => onTagClose(item, index)}
                                                    >
                                                      {item.name + createRangeTimeStr(item.timeSeg as string)}
                                                    </ShiftTag>
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          ))}
                                        </Space>
                                        {droppableProvidedRight.placeholder}
                                      </div>
                                    </div>
                                    <div className={styles.drag_right_bottom}>
                                      <TimeGrid timeArr={selectShiftList} />
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </DragDropContext>
                        </Form.Item>
                      );
                    })}
                    <Button type="dashed" onClick={() => add()} block>
                      新增班次类型
                    </Button>
                  </div>
                )}
              </Form.List>
            </Form>
          </div>
          {op === 'edit' && (
            <div className={styles.tips}>
              <Space>
                <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                <span>更改后不影响本月及历史数据，仅对未来月份生效。若未来班次发生调整，请重新排班。</span>
              </Space>
            </div>
          )}
        </div>
      </Modal>
      {messageContextHolder}
      {modalContextHolder}
    </>
  );
};

export default ShiftOps;
