import React, { ReactText, Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Paging, Table, usePaging, Input, CustomFilter, Form } from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import { DataAcquisitionFormProps } from '../../components/DataAcquisitionForm';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { DevicePageRequest, DevicePageResponse, getNotConfiguredEdgeDevicePage } from '../../api/edgeDevice';

function formatListData(response: DevicePageResponse): Array<TableData> {
  return (response.list ?? []).map(item => ({
    id: item.id,
    code: item.code,
    rootName: item.tenantCode + '+' + item.rootFullName,
    name: item.name,
    typeName: item.typeName,
  }));
}

export interface DeviceModalRef {
  selectedRowKeys: Array<ReactText> | undefined;
}

type SearchParams = Omit<DevicePageRequest, 'page' | 'size'>;

type FilterParams = Omit<SearchParams, 'deviceType' | 'physicalModelId'>;

interface TableData {
  id: number;
  code: string;
  rootName: string;
  name: string;
  typeName: string;
}

interface DeviceModalProps extends DataAcquisitionFormProps {
  deviceIds: Array<number> | undefined;
}

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
  },
  {
    title: '设备名称',
    dataIndex: 'name',
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    width: 300,
  },
  {
    title: '所属租户',
    dataIndex: 'rootName',
  },
];

const DeviceModal = React.forwardRef(({ state, deviceIds }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<ReactText> | undefined>(deviceIds);
  const { deviceType: deviceTypeArray, objectModalType } = state;

  const deviceType = deviceTypeArray?.slice(-1);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    setSearchParams({ ...val, deviceType, physicalModelId: objectModalType });
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: ReactText[]) => {
    setSelectedRowKeys(rowKeys);
  };

  useImperativeHandle(ref, () => ({
    selectedRowKeys,
  }));

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getNotConfiguredEdgeDevicePage({
          ...searchParams,
          deviceType,
          physicalModelId: objectModalType,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  return (
    <div className={styles.deviceModal}>
      <CustomFilter<FilterParams>
        form={form}
        colSpan={8}
        onFinish={val => onFinish(val as FilterParams)}
        onReset={onReset}
      >
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </CustomFilter>
      <div className={styles.table_text}>
        <div className={styles.action}>
          <span>点击前往</span>
          <Link to={'/device/manage/device/create'} className={styles.href}>
            添加设备
          </Link>
        </div>
      </div>
      <Table<TableData>
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        selectedCount={selectedRowKeys?.length ?? 0}
        showSelectedCount
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
});

export default DeviceModal;
