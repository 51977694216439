import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { Button, EllipsisSpan, PopConfirm, Table, useBreadcrumbRoutes, useUpdate, Wrapper } from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import ShiftOpsModal from './ShiftOpsModal';
import {
  apiV2WorkShiftDeletePost,
  apiV2WorkShiftListPost,
  V2WorkShiftCalendarDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

export type WorkShiftsItem = Exclude<V2WorkShiftCalendarDetailPostResponse['workShifts'], undefined>[number];

const TeamGroupManage: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [openShiftModal, setOpenShiftModal] = useState<boolean>(false);
  const [shiftList, setShiftList] = useState<WorkShiftsItem[]>([]);
  const [update, setUpdate] = useUpdate();
  const [opType, setOpType] = useState('add');
  const [currentRow, setCurrentRow] = useState<WorkShiftsItem>();
  const hasDeletePermission = useHasPermission(PermissionsType.B_WORKSHIFT_DELETE);
  const hasCreatePermission = useHasPermission(PermissionsType.B_WORKSHIFT_CREATE);
  const hasEditPermission = useHasPermission(PermissionsType.B_WORKSHIFT_UPDATE);

  useEffect(() => {
    setLoading(true);
    apiV2WorkShiftListPost({})
      .then(res => {
        setShiftList(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [update]);

  const buildColumns = [
    {
      title: '班次名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '班次时间',
      dataIndex: 'timeSeg',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v.split(';').join('; ')} />,
    },

    {
      title: '操作',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string, record: WorkShiftsItem) => {
        return (
          <Space size={16}>
            {hasEditPermission && (
              <Button
                type="link"
                onClick={() => {
                  setOpType('edit');
                  setCurrentRow(record);
                  setOpenShiftModal(true);
                }}
              >
                编辑
              </Button>
            )}
            {hasDeletePermission && (
              <PopConfirm
                placement="topLeft"
                title={() => {
                  return (
                    <>
                      <div>确认删除{record.name}?</div>
                      <div>历史生效的班次不受影响</div>
                    </>
                  );
                }}
                onConfirm={() => {
                  apiV2WorkShiftDeletePost({ id: record.id }).then(_ => setUpdate());
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];
  const confirmBtn = () => {
    setUpdate();
    setOpenShiftModal(false);
  };
  const cancelBtn = () => {
    setOpenShiftModal(false);
  };
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <Space style={{ marginBottom: 10 }}>
        {hasCreatePermission && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setOpType('add');
              setOpenShiftModal(true);
            }}
          >
            新增班次
          </Button>
        )}
      </Space>

      <Table rowKey="id" sticky dataSource={shiftList} loading={loading} columns={buildColumns} pagination={false} />

      {openShiftModal && <ShiftOpsModal op={opType} row={currentRow} cancel={cancelBtn} confirm={confirmBtn} />}
    </Wrapper>
  );
};

export default TeamGroupManage;
