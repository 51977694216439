import { Tree } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import type { DataNode } from 'antd/es/tree';
import styles from './index.module.scss';
import { Empty, Input, Key, Modal } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { GroupDetails } from '../..';
import { EnergyUnits } from '../ApplicationUnitTable';

const { Search } = Input;

function formatToNodeData(data: EnergyUnits[]): DataNode[] {
  return (data ?? []).map(i => {
    return {
      title: i.energyUnitName,
      key: i.energyUnitId as number,
      children: i.childList && i.childList.length > 0 ? formatToNodeData(i.childList) : [],
      disabled: !i.enableSelect,
    };
  });
}

const dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

const getParentIdsByNodeId = (data: DataNode[], nodeId: React.Key, path: React.Key[] = []): React.Key[] => {
  for (const node of data) {
    const id = node.key;
    path.push(node.key);
    if (id === nodeId) return path;
    if (node.children) {
      const findChildren = getParentIdsByNodeId(node.children, nodeId, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  appConfigEnergyData: EnergyUnits[];
  unitTableData: GroupDetails[];
  setUnitTableData: (v: GroupDetails[]) => void;
  energyGroupId?: Key;
  energyGroupName?: string;
  onChange?: (v: GroupDetails[]) => void;
  unitCheckedKeys: Key[];
  setUnitCheckedKeys: (v: Key[]) => void;
}

const UnitSelectModal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  appConfigEnergyData,
  unitTableData,
  setUnitTableData,
  energyGroupId,
  energyGroupName,
  onChange,
  unitCheckedKeys,
  setUnitCheckedKeys,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeNodeData, setTreeNodeData] = useState<DataNode[]>([]);

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.siteTreeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
            disabled: item.disabled,
            selectable: true,
          };
        }
        return {
          title,
          key: item.key,
          disabled: item.disabled,
          selectable: true,
        };
      });

    return loop(treeNodeData);
  }, [searchValue, treeNodeData]);

  useEffect(() => {
    if (appConfigEnergyData) {
      setTreeNodeData(formatToNodeData(appConfigEnergyData));
      generateList(formatToNodeData(appConfigEnergyData));
      setExpandedKeys(dataList.map(i => i.key));
    }
  }, [appConfigEnergyData]);

  const onCancel = () => {
    setTreeNodeData([]);
    setModalOpen(false);
  };

  const onOk = () => {
    if (unitTableData && unitTableData.length > 0) {
      const table = unitTableData.map(i => {
        if (i.energyGroupId === energyGroupId) {
          return {
            ...i,
            energyUnits: unitCheckedKeys?.map(key => {
              return { energyUnitId: key as number };
            }),
            amount: unitCheckedKeys?.length ?? 0,
          };
        }
        return i;
      });
      onChange?.(table);
      setUnitTableData(table);
    }

    setModalOpen(false);
  };

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newExpandedKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeNodeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    // 这里使用 allParentKeys 而不直接使用 expandKeys 是因为 allParentKeys 是某个节点的所有父节点, 而 expandKeys 是当前节点的父节点
    // disabled 会影响收起和选中之间的传导, 例如当某个节点被搜索到, 但他的某个上级节点被禁用, 该节点就不会被自动展开
    // 因此需要手动展开该节点的所有父节点, 即使父节点被禁用该节点也要被自动展开
    // 参考: https://github.com/ant-design/ant-design/issues/27478
    const allParentKeys = (newExpandedKeys as React.Key[]).map(key => {
      return getParentIdsByNodeId(treeNodeData, key);
    });
    setExpandedKeys(_.uniq(allParentKeys.flat()));
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onCheck = (keys: any) => {
    setUnitCheckedKeys(keys.checked);
  };

  return (
    <Modal title="选择应用单元" open={modalOpen} onCancel={onCancel} onOk={onOk}>
      <div style={{ padding: '0 40px' }}>
        <p> 用能分析组：{energyGroupName ?? '--'}</p>
        <div>
          <Search style={{ margin: '8px 0px' }} placeholder="请输入关键字" onChange={onInputChange} />
          <p style={{ margin: '8px 0px' }}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginRight: 4 }} />
            不可选适用时间范围内已配置或无权限的用能单元
          </p>
          {treeData.length ? (
            <div className={styles.treeBackground} style={{ overflowY: 'auto', height: 500 }}>
              <Tree
                checkable
                checkStrictly
                showLine
                checkedKeys={unitCheckedKeys}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                treeData={treeData}
                onCheck={onCheck}
              />
            </div>
          ) : (
            <Empty style={{ padding: '100px 0' }} description="暂无数据" />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UnitSelectModal;
