import { Button, Empty, FormTitle, SubContent, useBreadcrumbRoutes, useUpdate, Wrapper } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

import styles from './index.module.scss';
import CalendarCard from './CalendarCard';

import ShiftOps from './ShiftOpModal/ShiftOps';
import { useNavigate } from 'react-router-dom';

import { apiV2WorkShiftCalendarListPost, V2WorkShiftCalendarListPostResponse } from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

export type CardListItem = Exclude<V2WorkShiftCalendarListPostResponse['list'], undefined>[number];

const ShiftCalendar: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openOpsModal, setOpenOpsModal] = useState(false);
  const [cardList, setCardList] = useState<CardListItem[]>([]);
  const [update, setUpdate] = useUpdate();
  const navigate = useNavigate();
  const hasCreatePermission = useHasPermission(PermissionsType.P_WORKSHITFCALENDARCREATE);

  const addBtn = () => {
    // setOpenOpsModal(true);
    navigate(`/energy/teamShift/shiftCalendar/create`);
  };
  useEffect(() => {
    apiV2WorkShiftCalendarListPost({}).then(res => setCardList(res?.list ?? []));
  }, [update]);
  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
        <FormTitle title="班次日历" />
        <SubContent>
          <Row style={{ paddingBottom: 15 }}>
            {hasCreatePermission && (
              <Button type="primary" icon={<PlusOutlined />} onClick={addBtn}>
                新增班次日历
              </Button>
            )}
          </Row>

          <Row gutter={15}>
            {cardList.map(item => {
              return (
                <Col key={item.id} sm={12} lg={8} md={12}>
                  <CalendarCard data={item} update={setUpdate}></CalendarCard>
                </Col>
              );
            })}
          </Row>
          {cardList.length === 0 && (
            <div className={styles.empty_box}>
              <Empty />
            </div>
          )}
        </SubContent>
      </Wrapper>
      {openOpsModal && (
        <ShiftOps
          confirm={() => {
            setUpdate();
            setOpenOpsModal(false);
          }}
          cancel={() => setOpenOpsModal(false)}
        />
      )}
    </>
  );
};
export default ShiftCalendar;
