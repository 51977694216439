import { useEffect, useMemo, useState } from 'react';
import { Form, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  useBreadcrumbRoutes,
  Paging,
  CustomFilter,
  TreeSelect,
  DatePicker,
  Checkbox,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import {
  apiV2ProductionBaseCompleteTreePost,
  V2ProductionBaseCompleteTreePostResponse,
  apiV2MesOrderPagePost,
  apiV2MesOrderDelPost,
  V2MesOrderPagePostResponse,
  V2MesOrderPagePostRequest,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import UploadImport from '@/components/UploadImport';
import ImportLog from '@/components/ImportLog';
import { LogType } from '@/components/ImportLog/Prop';
import { omit } from 'lodash-es';

export type OutputItem = Exclude<V2MesOrderPagePostResponse['list'], undefined>[number];

const columns: ColumnsType<OutputItem> = [
  {
    title: '订单号',
    dataIndex: 'orderNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生产基地',
    dataIndex: 'productionBaseName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工作中心',
    dataIndex: 'workCenterName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工序',
    dataIndex: 'workProcedureName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工站',
    dataIndex: 'workStationName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '产出物',
    dataIndex: 'outputProductName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合格品数',
    dataIndex: 'qualifiedNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '不合格品数',
    dataIndex: 'nonQualifiedNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始生产时间',
    dataIndex: 'inputTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '结束生产时间',
    dataIndex: 'outputTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<V2MesOrderPagePostRequest, 'page' | 'size'> {}

type listTreeProp = Exclude<V2ProductionBaseCompleteTreePostResponse['list'], undefined>[number];
type workCenterTreeProp = Exclude<listTreeProp['workCenterTree'], undefined>[number];
type procedureTreeProp = Exclude<workCenterTreeProp['procedureTree'], undefined>[number];
type stationTreeProp = Exclude<procedureTreeProp['stationTree'], undefined>[number];

type NodeDataProp = listTreeProp & workCenterTreeProp & procedureTreeProp & stationTreeProp;

// 生产过程树最多五层，依次为 生产基地 -> 工作中心 -> 工序 -> 工站 -> 用能单元
const TREE_CHILD_KEYS = ['productionBase', 'workCenterTree', 'procedureTree', 'stationTree'];

function formatToNodeData(data: any, depth: number) {
  return (data ?? []).map((i: any, index: number) => {
    let title = i.name ?? i.procedureName ?? i.stationName ?? i.energyUnitName;
    let id = i.id ?? i.procedureId ?? i.stationId ?? i.energyUnitId;
    let key = TREE_CHILD_KEYS[depth] + '-' + id;

    return {
      title,
      key,
      id,
      disabled: depth === 0 || depth === 1,
      children: formatToNodeData(i[TREE_CHILD_KEYS[depth + 1] as keyof NodeDataProp], depth + 1),
    };
  });
}

const InputOutputInfo: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openImportLogModal, setOpenImportLogModal] = useState<boolean>(false);
  const { searchParams, setTotalCount, totalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasImport = useHasPermission(PermissionsType.B_IMPORTPRODUCTIONREPORTING); // 导入
  // const hasImportLog = useHasPermission(PermissionsType.B_IMPORTPRODUCTIONREPORTINGLOG); // 导入日志
  const [filterFlag, setFilterFlag] = useState<boolean>(false);
  const hasDelete = useHasPermission(PermissionsType.B_DELETEMESDATA); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [data, setData] = useState<any[]>();

  // 获取关联生产过程
  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      setData(formatToNodeData(res.list, 0));
    });
  }, []);

  // 关联过程树
  const processAllTreeData = useMemo(() => {
    const loop = (data: any[], node?: any): any[] =>
      data.map(item => {
        const label = node?.label ? node?.label + '-' + item.title : item.title;
        const ids = node?.ids ? node?.ids + '-' + item.id : item.id;
        return {
          title: item.title,
          label,
          ids,
          children: loop(item.children, { ...item, label, ids }),
          disabled: item.disabled,
        };
      });
    return data ? loop(data) : [];
  }, [data]);

  const { data: tableData, loading } = useRequest(
    () => {
      const param = omit(searchParams, ['pageOffset', 'pageSize']);
      return apiV2MesOrderPagePost({
        ...param,
        filterFlag: filterFlag ? 1 : 0,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      refreshDeps: [searchParams, setTotalCount, filterFlag, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2MesOrderDelPost({ ids: [id] }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <PopConfirm
              placement="top"
              disabled={!hasDelete}
              title="您是否删除本条投产信息，删除后不支持恢复"
              onConfirm={() => onDelete(record.id)}
            >
              <Button type="link" disabled={!hasDelete}>
                删除
              </Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: any) => {
    const param = omit(v, ['dateRange']);
    finish({
      ...param,
      startOutputTime: v.dateRange?.[0]?.startOf('d')?.unix(),
      endOutputTime: v.dateRange?.[1]?.endOf('d')?.unix(),
    });
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} colSpan={6} onReset={onReset}>
      <Form.Item label="订单号" name="orderNumber">
        <Input placeholder="请输入订单号" />
      </Form.Item>
      <Form.Item name="processCode" label="关联生产过程">
        <TreeSelect
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          allowClear
          fieldNames={{ value: 'ids' }}
          treeNodeLabelProp="label"
          treeDefaultExpandAll
          treeData={processAllTreeData}
        />
      </Form.Item>
      <Form.Item name="outputProductName" label="产出物">
        <Input placeholder="请输入产出物名称" />
      </Form.Item>
      <Form.Item label="结束生产日期" name="dateRange">
        <DatePicker.RangePicker style={{ width: '100%' }} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <Space size={16} style={{ marginBottom: 10 }}>
        {hasImport && (
          <Button type="primary" onClick={() => setOpenImportModal(true)}>
            批量导入
          </Button>
        )}
        <Checkbox
          value={filterFlag}
          onChange={e => {
            setFilterFlag(e.target.checked);
          }}
        >
          可能异常的数据
        </Checkbox>
      </Space>
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={tableData} />
      <Paging pagingInfo={pagingInfo} />
      {openImportModal && (
        <UploadImport title="投产信息导入" type={LogType.MES} setOpen={setOpenImportModal} updateFn={updateFn} />
      )}
      {openImportLogModal && <ImportLog onClose={() => setOpenImportLogModal(false)} type={LogType.MES} />}
    </Wrapper>
  );
};

export default InputOutputInfo;
